"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "../img/*.{png,jpg,jpeg,gif,svg}";

// UIkit
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

// loads the Icon plugin
UIkit.use(Icons);



function findGetParameter(parameterName) {
	var result = null,
		tmp = [];
	location.search
		.substr(1)
		.split("&")
		.forEach(function (item) {
			tmp = item.split("=");
			if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
		});
	return result;
}
//console.log(findGetParameter("OM"));
document.addEventListener("DOMContentLoaded", function () {
	if (findGetParameter("OM") == 1 || runModal == true) {
		UIkit.modal("#contactModal").show();
		console.log("modal_run");
	} else {
		console.log("modal hide");
	}
});
// import stylu
import "./../sass/index.scss";

// tiny slider
//import { tns } from "tiny-slider/src/tiny-slider";

// minimasonry
import Minigrid from "minigrid";

const gridQ = document.querySelectorAll(".grid");


	if (gridQ.length) {
		//console.log("prošel sdfsd");
		//inicializace gridu
		(function () {
			var grid;
			function init() {
				grid = new Minigrid({
					container: ".grid",
					item: ".grid-item",
					gutter: 7,
				});
				grid.mount();
				//console.log('card A mount');
			}

			// mount
			function updateMini() {
				grid.mount();				
				//console.log("card A mount update");
			}

			const gridItemImg = document.querySelectorAll(".gridItemImg");
			var iA = 1;
			gridItemImg.forEach(function (img) {
				//console.log("card A mount img INI " + iA);			
				img.addEventListener("load", function () {
					grid.mount();
					//console.log("card A mount img " + iA);
					iA++;
				});

			});
			document.addEventListener("DOMContentLoaded", init);
			window.addEventListener("resize", updateMini);
			setTimeout(() => {
				updateMini();
			}, 200);
		})();
	}

const gridQ2 = document.querySelectorAll(".grid2");

	if (gridQ2.length) {
		(function () {
			var grid2;
			function init2() {
				grid2 = new Minigrid({
					container: ".grid2",
					item: ".grid-item2",
					gutter: 7,
				});
				grid2.mount();
			}

			// mount
			function updateMini2() {
				grid2.mount();
				//image.addEventListener('load', function () { tadyZavolat() })
			}

			const gridItemImg2 = document.querySelectorAll(".gridItemImg2");
			gridItemImg2.forEach(function (img) {
				img.addEventListener("load", function () {
					//console.log("obrazek nascten 2");
					grid2.mount();
				});
			});

			document.addEventListener("DOMContentLoaded", init2);
			window.addEventListener("resize", updateMini2);
			setTimeout(() => {
				updateMini2();
			}, 200);
		})();
	}

const gridIn = document.querySelectorAll(".gridIn");

	if (gridIn.length) {
		//console.log("prošel gridIn");
		//inicializace gridu
		(function () {
			var grid;
			function init() {
				grid = new Minigrid({
					container: ".gridIn",
					item: ".gridIn-item",
					gutter: 7,
				});
				grid.mount();
			}

			// mount
			function updateMini() {
				grid.mount();
				//image.addEventListener('load', function () { tadyZavolat() })
			}

			const gridItemImg = document.querySelectorAll(".gridItemImg");
			gridItemImg.forEach(function (img) {
				img.addEventListener("load", function () {
					//console.log("obrazek nascten");
					grid.mount();
				});
			});

			document.addEventListener("DOMContentLoaded", init);
			window.addEventListener("resize", updateMini);
		})();
	}

// overlayscrollbars
import { overscroll } from "overlayscrollbars/js/OverlayScrollbars";

var scrollM;
var scrollT;

document.addEventListener("DOMContentLoaded", function () {
	scrollM = OverlayScrollbars(document.getElementById("scrollM"), {
		className: "os-theme-dark",
		resize: "none",
		sizeAutoCapable: true,
		paddingAbsolute: true,
		scrollbars: {
			visibility: false,
			clickScrolling: true,
		},
	});
	scrollT = OverlayScrollbars(document.getElementById("scrollT"), {
		className: "os-theme-dark",
		resize: "none",
		sizeAutoCapable: true,
		paddingAbsolute: true,
		scrollbars: {
			clickScrolling: true,
		},
	});
	var mTop = document.getElementById("mToTop");
	try {
		mTop.addEventListener("click", function () {
			scrollM.scroll(0, 800);
		});
	} catch (err) {}

	var tTop = document.getElementById("tToTop");
	try {
		tTop.addEventListener("click", function () {
			scrollT.scroll(0, 800);
		});
	} catch (err) {}
});
window.addEventListener("resize", function () {
	try {
		scrollM.update();
		scrollT.update();
	} catch (err) {}
});

//menu
